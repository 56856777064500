@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #202020;
    --background-dark: #121212;
    --background-light: #303030;
    --background-alt: #283dff;
    --foreground: #e0e0e0;
    --foreground-dark: #cccccc;
    --foreground-light: #f0f0f0;
    --foreground-alt: #101010;
    --border: #404040;
    --input: #cccccc;
    --header: #101010;
    --footer: #101010;
    --segment: #f0f0f0;
    --ring: #08dee3;
    --muted: #404040;
    --muted-foreground: #808080;
    --accent: #0779c6;
    --accent-foreground: #000000;
    --primary: #232323;
    --primary-foreground: #cccccc;
    --secondary: #191919;
    --secondary-foreground: #999999;
    --warning: #ff0000;
    --warning-foreground: #e0e0e0;
    --active: #0b8ef1;
    --border: #303030;
    --black: #000000;

    /* buttons */
    --button-primary-bg: #08dee3;
    --button-primary-fg: #000000;
    --button-primary-bg-hover: #0b8ef1;
    --button-primary-fg-hover: #000000;

    --button-secondary-bg: #08dee3;
    --button-secondary-fg: #000000;
    --button-secondary-bg-hover: #0b8ef1;
    --button-secondary-fg-hover: #000000;

    --button-outline-bg: #08dee3;
    --button-outline-fg: #cccccc;
    --button-outline-bg-hover: #0b8ef1;
    --button-outline-fg-hover: #000000;

    --button-destructive-bg: #ff0000;
    --button-destructive-fg: #ffffff;
    --button-destructive-bg-hover: #808080;
    --button-destructive-fg-hover: #000000;

    --button-ghost-bg: #333333;
    --button-ghost-fg: #777777;
    --button-ghost-bg-hover: #777777;
    --button-ghost-fg-hover: #aaaaaa;

    --button-link-bg: #08dee3;
    --button-link-fg: #eeeeee;
    --button-link-bg-hover: #08dee3;
    --button-link-fg-hover: #000000;

    /* badges */
    --badge-listing: #ff0000;
    --badge-listing-foreground: #cccccc;

    --badge-want: #ff0000;
    --badge-want-foreground: #cccccc;

    --badge-garage: #ff0000;
    --badge-garage-foreground: #cccccc;

    --badge-event: #ff0000;
    --badge-event-foreground: #cccccc;

    --badge-group: #ff0000;
    --badge-group-foreground: #cccccc;

    --badge-record: #ff0000;
    --badge-record-foreground: #cccccc;

    --badge-status: #ff0000;
    --badge-status-foreground: #cccccc;

    --badge-default: #ff0000;
    --badge-default-foreground: #cccccc;
  }
}

html,
body {
  scroll-behavior: smooth;
}

.main-page {
  min-height: calc(100vh - 85px);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-background text-foreground;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primaryColor: rgb(122, 198, 198);
  --secondaryColor: magenta;
  --tertiaryColor: gold;
  --successColor: green;
  --errorColor: red;
}

/* h1 {
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 30px;
  margin: 5px 0;
}

h2 {
  color: var(--primaryColor);
  font-weight: bold;
  font-size: 24px;
  margin: 5px 0;
}

h3 {
  color: var(--primaryColor);
  font-weight: bold;
  font-size: 18px;
  margin: 5px 0;
} */

ul li {
  display: block;
  margin-left: 15px;
  list-style-type: initial;
}

.nav-item[aria-current="page"] {
  color: var(--secondaryColor);
}

.nav-item {
  display: block;
}

.expand {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.embla__slide.peek {
  position: relative;
  flex: 0 0 80% !important; /* Adjust the width to allow peeking */
  margin-right: 15px !important; /* Space between slides */
  margin-left: 15px !important;
}

.embla {
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.2rem;
  margin-bottom: 2rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3rem;
  height: 3rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #404040;
  transition: all 0.2s ease-out;
}

.embla__button:hover {
  background-color: #404040;
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
  opacity: 0.2;
}
.embla__button__svg {
  width: 30%;
  height: 30%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
  border: 1px solid #404040;
  transition: all 0.2s ease-out;
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
  background-color: #404040;
}

.spinner {
  padding: 0.6rem 0;
}

.spinner-circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  padding: 0.5rem 1rem;
}

.spinner-circle:before {
  z-index: 5;
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 3px dashed #fff;
  width: 25px;
  height: 25px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  animation: loading 1s linear infinite;
}

.spinner-circle:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 3px dashed #fff;
  width: 25px;
  height: 25px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  animation: loading 1s ease infinite;
  z-index: 10;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #101010 inset !important;
  -webkit-text-fill-color: white !important;
  border: 1px solid hsl(var(--input));
}

#root {
  .rsw-toolbar {
    background-color: #090909;
    border-bottom: 1px solid var(--black);
  }

  .rsw-separator {
    border-right: 1px solid var(--black);
  }

  .rsw-editor {
    border: 1px solid var(--black);
  }

  .rsw-ce:focus {
    outline: 1px solid var(--black);
  }

  .rsw-btn {
    color: var(--primary-foreground);

    &:hover {
      background: var(--black);
    }
  }

  .rsw-dd {
    background: var(--black);
    padding: 3px;
    border-radius: 5px;
  }

  .rsw-ce {
    background: var(--background-dark);
  }
}

.welcome-page {
  .global-header & {
    display: none;
  }
}
